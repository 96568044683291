<template>
  <div class="mt-3">
    <label
      >{{ label }}
      <span v-if="required === 1 ? true : false" class="text-error"
        >*</span
      ></label
    >
    <b-form-input
      :class="[isValidate ? 'border-error' : '']"
      v-model="data"
      :placeholder="placeholder"
      @change="handleChangeValue"
    ></b-form-input>
    <div v-if="isValidate" class="text-error ft-14 mt-1">
      กรุณากรอกข้อมูล 
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: false,
      type: String | Number
    },
    placeholder: {
      required: false,
      type: String
    },
    label: {
      required: false,
      type: String
    },
    fieldtype: {
      required: false,
      type: Number
    },
    questionId: {
      required: false,
      type: Number
    },
    required: {
      required: false,
      type: Number
    },
    v: {
      required: false,
      type: Object
    },
    isValidate:{
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      data: this.value
    };
  },
  methods: {
    handleChangeValue(value) {
      this.$emit("handleChangeValue", {
        answer: value,
        type: this.fieldtype,
        questionId: this.questionId
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.border-error {
  border-color: red;
}
.text-error {
  color: red;
}
.ft-14{
  font-size: 14px;
}
</style>