<template>
  <div class="mt-3">
    <label
      >{{ label }}
      <span v-if="required === 1 ? true : false" class="text-error"
        >*</span
      ></label
    >
    <b-form-group>
      <b-form-radio-group
        id="multiple-choice"
        v-model="selected"
        :options="options"
        :value-field="valueField"
        :text-field="textField"
        @change="handleChangeValue"
        name="multiple-options"
        stacked
      ></b-form-radio-group>
      <div v-if="isValidate" class="text-error ft-14 mt-1">กรุณากรอกข้อมูล</div>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      required: true,
      type: Array
    },
    valueField: {
      required: true,
      type: String
    },
    textField: {
      required: true,
      type: String
    },
    label: {
      required: true,
      type: String
    },
    required: {
      required: false,
      type: Number
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    fieldtype: {
      required: true,
      type: Number
    },
    questionId: {
      required: true,
      type: Number
    }
  },
  created() {
    this.getAnswer();
  },
  data() {
    return {
      selected: ""
    };
  },
  methods: {
    getAnswer() {
      for (const choice of this.options) {
        if (choice.check) {
          this.selected = choice.choice_id;
          break;
        } else {
          if (choice.is_default) {
            this.selected = choice.choice_id;
          }
        }
      }
    },
    handleChangeValue(value) {
      const findValue = this.options.find(el => el.choice_id === value);
      this.$emit("handleChangeMultipleChoice", {
        answer_id: value,
        answer: findValue.choice,
        type: this.fieldtype,
        questionId: this.questionId
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .custom-control {
  z-index: 0;
}
.text-error {
  color: red;
}
.text-error {
  color: red;
}
.ft-14 {
  font-size: 14px;
}
</style>