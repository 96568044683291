<template>
  <div class="mt-3">
    <UploadFile
      :textFloat="label"
      :placeholder="label"
      accept="image/png, image/jpeg"
      @onFileChange="handleFileChange"
      :fileName="images"
    />
    <div v-if="isValidate" class="text-error ft-14">กรุณาเลือกรูปภาพ</div>
    <div class="panel-upload" v-if="images">
      <div
        class="panel-bg-file-img"
        v-bind:style="{
          backgroundImage: 'url(' + images + ')',
          borderColor: $store.state.theme.themePrimaryColor
        }"
      >
        <font-awesome-icon
          class="icon-delete"
          icon="times-circle"
          color="#FFFFFF"
          @click.prevent="deleteImage"
          v-if="images"
        />
        <!-- <font-awesome-icon
          icon="plus"
          color="#40a9d1"
          class="icon-add"
          v-if="!images"
        /> -->
        <input
          type="file"
          v-on:change="onFileChange"
          :name="name"
          ref="input"
          v-if="!images"
          accept="image/png, image/jpeg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UploadFile from "@/components/custom-fields/fields/UploadFile";
export default {
  components: {
    UploadFile
  },
  props: {
    required: {
      required: false,
      type: Number
    },
    name: {
      required: false,
      type: String
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    label: {
      required: false,
      type: String
    },
    fieldtype: {
      required: true,
      type: Number
    },
    questionId: {
      required: true,
      type: Number
    },
    img: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: this.img.answer_list[0].answer,
      fileName: "",
      value: "",
      isLoadingImage: false,
      showPreview: ""
    };
  },
  created() {
    this.fileName = this.img.answer_list[0].answer;
  },
  methods: {
    handleFileChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;
        this.showPreview = this.images;
        this.$emit("onChangeImage", {
          answer: this.images,
          answer_id: 0,
          type: this.fieldtype,
          questionId: this.questionId
        });
      };
    },
    deleteImage() {
      this.images = "";
      this.showPreview = "";
      this.$emit("deleteImage", {
        type: this.fieldtype,
        questionId: this.questionId
      });
    },
    saveImagetoDb: async function (img) {
      this.loadingImage = false;
      const form = {
        Base64String: img
      };
      return await this.$axios
        .post(
          `${process.env.VUE_APP_API}/api/v1/user/upload_custom_answer_image`,
          form
        )
        .then(async data => {
          this.isLoading = false;
          if (data.result == 1) {
            return data.detail;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-upload {
  position: relative;
  margin-bottom: 15px;
}
.icon-add {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 25px;
  height: 25px;
}
.icon-delete {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #707070;
}
.label-file {
  top: 0px;
  position: absolute;
  right: 60px;
  margin-right: 5px;
}
.panel-bg-file-img {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: white;
  /* padding-bottom: 20%; */
  height: 100px;
  border: 2px dashed;
  border-radius: 6px;
  width: 100px;
  cursor: pointer;
}
.icon-camera {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #afce38;
  border-radius: 50%;
  padding: 7px;
  cursor: pointer;
}
input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
  width: 100%;
  height: 100%;
  line-height: 95px;
  position: absolute;
  opacity: 0;
}
input[type="file"] {
  display: none;
}
.detail-format {
  color: #9b9b9b;
  font-size: 12px;
  font-family: "Kanit-Light";
  margin-top: 3px;
  margin-bottom: 0px;
  /* height: 35px; */
}
.div-input {
  margin-bottom: 15px;
  position: relative;
  white-space: nowrap;
}
.input-custom {
  padding: 0px;
}
.input-custom > label {
  color: #16274a;
  font-size: 16px;
  margin-bottom: 2px;
}
input.custom-input {
  background-color: white;
  border: 1px solid #bcbcbc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 7px 10px;
}
label[size="lg"].btn-main,
input[size="lg"].custom-input {
  height: 45px;
}
.input-custom.error .custom-input {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: #16274a;
  font-size: 15px;
  font-family: "Kanit-Light";
}
.display-only {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.btn-main {
  //   width: 120px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  //   background: #16274a;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: white;
  padding: 8px 10px;
}
.btn-download-file {
  padding: 0;
  text-decoration: underline;
}
.delete-icon {
  position: absolute;
  right: 5%;
  top: 10px;
}
.text-error {
  color: red;
}
.ft-14 {
  font-size: 14px;
}
</style>